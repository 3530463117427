import { FC, Fragment } from 'react';
import useBusinessClassOptionsDropdownCollection from '../../../../../../../../hooks/dropdown-collection/useBusinessClassOptionsDropdownCollection';
import { useSharedStyles } from '../../../../../shared/styles';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import { Button, TextField, Typography, Grid, } from '@mui/material';
import { IBusinessClientClass, IBusinessClientDetailFormValues } from '../../../../../../../../models';
import GenericDropdown from '../../../../../../../../components/generic-dropdown/GenericDropdown';
import { Close } from '@mui/icons-material';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';

export default function Classes() {
    const classes = useSharedStyles();

    const businessClassOptionsRequest = useBusinessClassOptionsDropdownCollection();
    const businessClassOptions = businessClassOptionsRequest.data;
    const businessClassLoading = businessClassOptionsRequest.status === "loading";


    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const businessClientClasses = values.classes;

    const businessClientClassesErrors = errors.classes as FormikErrors<IBusinessClientClass>[];

    const validateAction = menusSubMenusValidationStatusMap.get('classes');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />

            <FieldArray name='classes'>
                {({ push, remove }) => {
                    return (
                        <div>
                            {businessClientClasses.length > 0 &&
                                businessClientClasses.map((clientClass, index) => {
                                    return (
                                        <Fragment key={`${index}`}>
                                            <Typography variant='h5' component='h5' className={classes.classText}>
                                                Class {index + 1}
                                            </Typography>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item xs={12}>
                                                    <Field name={`classes.${index}.name`}>
                                                        {({ field }) => {
                                                            return (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Class Name'
                                                                    name={`classes.${index}.name`}
                                                                    value={businessClientClasses[index].name}
                                                                    onBlur={handleBlur}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <GenericDropdown handleBlur={handleBlur}
                                                        options={businessClassOptions?.waitingPeriods ?? []}
                                                        isLoading={businessClassLoading}
                                                        setFieldValue={setFieldValue}
                                                        value={clientClass.waitingPeriod ?? ''}
                                                        error={businessClientClassesErrors?.[index]?.waitingPeriod}
                                                        label='Waiting Period'
                                                        id={`classes.${index}.waitingPeriod`}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Field name={`classes.${index}.benefitGuideUrl`}>
                                                        {({ field }) => {
                                                            return (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    autoComplete='nope'
                                                                    size='small'
                                                                    label='Benefit Guide URL'
                                                                    name={`classes.${index}.benefitGuideUrl`}
                                                                    value={businessClientClasses[index].benefitGuideUrl || ''}
                                                                    onBlur={handleBlur}
                                                                    placeholder='www.example.com'
                                                                    error={
                                                                        errors &&
                                                                            errors.classes &&
                                                                            errors.classes[index] &&
                                                                            // @ts-ignore
                                                                            errors.classes[index].benefitGuideUrl
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    // @ts-ignore
                                                                    helperText={
                                                                        errors &&
                                                                        errors.classes &&
                                                                        errors.classes[index] &&
                                                                        // @ts-ignore
                                                                        errors.classes[index].benefitGuideUrl
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Field name={`classes.${index}.videoUrl`}>
                                                        {({ field }) => {
                                                            return (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Video URL'
                                                                    name={`classes.${index}.videoUrl`}
                                                                    value={businessClientClasses[index].videoUrl || ''}
                                                                    onBlur={handleBlur}
                                                                    placeholder='www.example.com'
                                                                    error={
                                                                        errors &&
                                                                            errors.classes &&
                                                                            errors.classes[index] &&
                                                                            // @ts-ignore
                                                                            errors.classes[index].videoUrl
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    // @ts-ignore
                                                                    helperText={
                                                                        errors &&
                                                                        errors.classes &&
                                                                        errors.classes[index] &&
                                                                        // @ts-ignore
                                                                        errors.classes[index].videoUrl
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={4}>
                                                    <GenericDropdown handleBlur={handleBlur}
                                                        options={businessClassOptions?.classPayCycles ?? []}
                                                        isLoading={businessClassLoading}
                                                        setFieldValue={setFieldValue}
                                                        value={clientClass.classPayCycle ?? ''}
                                                        error={businessClientClassesErrors?.[index]?.classPayCycle}
                                                        label='Class Pay Cycle'
                                                        id={`classes.${index}.classPayCycle`}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} className={classes.removeButtonWrapper}>
                                                    <Button
                                                        className={classes.removeButton}
                                                        startIcon={<Close />}
                                                        onClick={() => {
                                                            remove(index);
                                                            const newQrCodes = [...values.qrCodes];
                                                            setFieldValue(
                                                                'qrCodes',
                                                                newQrCodes.filter(code => code.businessClientClassId !== clientClass.businessClientClassId)
                                                            );
                                                        }}
                                                    >
                                                        Remove Class
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    );
                                })}
                            <Button
                                color='primary'
                                variant='contained'
                                className={classes.addClassButton}
                                onClick={() =>
                                    push({
                                        name: '',
                                        videoUrl: '',
                                        benefitGuideUrl: '',
                                        waitingPeriod: ''
                                    })
                                }
                            >
                                Add Class
                            </Button>
                        </div>
                    );
                }}
            </FieldArray>

        </div>
    )
}
